import React, { useState, useEffect } from "react";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-MVQ7XGT", // あなたのGTM IDを設定
};


const goldTextStyle = {
  fontFamily:
    "ads-ayumin, 'ヒラギノ丸ゴ ProN', Roboto, 'Droid Sans', sans-serif",
  opacity: 0,
  fontWeight: "bold",
  fontStyle: "normal",
  background:
    "linear-gradient(180deg, #B39855 0%, rgb(251, 235, 164) 50%, #B39855 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  display: "inline-block",
};

function App() {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);

    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        pagePath: window.location.pathname,
      },
    });
  }, []);

  const catSize = 50; // 猫のサイズ (px)
  const gravity = 0.5; // 重力加速度

  // 使用する猫の絵文字のリスト
  const emojiList = [
    "😺",
    "😸",
    "😹",
    "😻",
    "😼",
    "😽",
    "🙀",
    "😿",
    "😾",
    "🐱",
    "🐯",
  ];

  // 30匹の猫の初期状態を生成
  const createInitialCats = () => {
    return Array.from({ length: 30 }, (_, i) => ({
      id: i,
      // x座標はウィンドウ幅に応じランダムに配置
      x: Math.random() * (window.innerWidth - catSize),
      // y座標は画面上部から少し外れた位置（負の値）で開始
      y: -Math.random() * 200,
      velocity: Math.random() * 3 + 1, // ランダムな初期落下速度 (1～4程度)
      vx: (Math.random() - 0.5) * 2, // 横方向の速度（-1～1）
      angle: Math.random() * 360, // 初期の回転角度
      // 以前より速く回転する（-1～1 程度の角速度）
      angularVelocity: (Math.random() - 0.5) * 2,
      // ランダムな絵文字を選択
      emoji: emojiList[Math.floor(Math.random() * emojiList.length)],
    }));
  };

  const [cats, setCats] = useState(createInitialCats());

  // コンポーネントのマウント時にスクロールバーを非表示にする
  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  // 重力とバウンドのアニメーションループ
  useEffect(() => {
    let animationFrameId;

    const update = () => {
      setCats((prevCats) =>
        prevCats.map((cat) => {
          let newVelocity = cat.velocity + gravity;
          let newY = cat.y + newVelocity;
          let newX = cat.x + cat.vx;
          let newAngle = cat.angle + cat.angularVelocity;
          let newVx = cat.vx;

          const bottom = window.innerHeight - catSize;
          const right = window.innerWidth - catSize;

          // 画面下部に到達した場合、バウンドさせる（回転速度は変化させない）
          if (newY > bottom) {
            newY = bottom;
            newVelocity = -newVelocity * 0.7;
          }

          // 横方向の境界で反転させる
          if (newX < 0) {
            newX = 0;
            newVx = -cat.vx;
          } else if (newX > right) {
            newX = right;
            newVx = -cat.vx;
          }

          return {
            ...cat,
            velocity: newVelocity,
            y: newY,
            x: newX,
            angle: newAngle,
            vx: newVx,
          };
        })
      );
      animationFrameId = requestAnimationFrame(update);
    };

    animationFrameId = requestAnimationFrame(update);
    return () => cancelAnimationFrame(animationFrameId);
  }, []);

  // ランダムな時間間隔で猫をジャンプさせる（ジャンプの衝撃を倍に変更）
  useEffect(() => {
    let jumpTimeoutId;
    const scheduleJump = () => {
      // 1秒～5秒のランダムな遅延(1000～5000ms)
      const delay = Math.random() * 4000 + 1000;
      jumpTimeoutId = setTimeout(() => {
        setCats((prevCats) => {
          if (prevCats.length === 0) return prevCats;
          // ランダムな猫を選択
          const jumpIndex = Math.floor(Math.random() * prevCats.length);
          return prevCats.map((cat, index) => {
            if (index === jumpIndex) {
              // ここで猫に上向きの衝撃を与える（velocityを-20に設定）
              return { ...cat, velocity: -20 };
            }
            return cat;
          });
        });
        scheduleJump(); // 次のジャンプをスケジュール
      }, delay);
    };
    scheduleJump();
    return () => clearTimeout(jumpTimeoutId);
  }, []);

  // 画面タッチ/クリックで猫をジャンプさせる処理
  const handleScreenTouch = (e) => {
    // タッチした場所の座標を取得
    const touchX = e.clientX || (e.touches && e.touches[0].clientX);
    const touchY = e.clientY || (e.touches && e.touches[0].clientY);
    
    // ジャンプさせる猫の数をランダムに決定（1〜5匹）
    const jumpCount = Math.floor(Math.random() * 5) + 1;
    
    setCats((prevCats) => {
      // 各猫とタッチ位置の距離を計算
      const catsWithDistance = prevCats.map(cat => ({
        ...cat,
        distance: Math.sqrt(Math.pow(cat.x - touchX, 2) + Math.pow(cat.y - touchY, 2))
      }));
      
      // 距離が近い順にソート
      catsWithDistance.sort((a, b) => a.distance - b.distance);
      
      // 最も近い猫たちをジャンプさせる
      return prevCats.map(cat => {
        const catIndex = catsWithDistance.findIndex(c => c.id === cat.id);
        if (catIndex < jumpCount) {
          // ジャンプの強さをランダムに設定（-15〜-25）
          const jumpPower = -(Math.random() * 10 + 15);
          return { ...cat, velocity: jumpPower };
        }
        return cat;
      });
    });
  };

  return (
    <>
      {/* キーフレームを定義 */}
      <style>{`
        @-webkit-keyframes enlarge {
          from {  
            opacity: 0;
            transform: scale(0);
          }
          to {
            opacity: 1;
            transform: scale(1);
          }
        }
        @keyframes enlarge {
          from {  
            opacity: 0;
            transform: scale(0);
          }
          to {
            opacity: 1;
            transform: scale(1);
          }
        }
      `}</style>
      <div
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          backgroundColor: "#FFFAE3", // 明るい背景色（淡いクリーム色）
        }}
        onClick={handleScreenTouch}
        onTouchStart={handleScreenTouch}
      >
        {/* 各猫をレンダリング */}
        {cats.map((cat) => (
          <div
            key={cat.id}
            style={{
              position: "absolute",
              left: cat.x,
              top: cat.y,
              // translate(-50%, -50%) で中心を合わせ、rotateで傾きを付ける
              transform: `translate(-50%, -50%) rotate(${cat.angle}deg)`,
              fontSize: "48px",
            }}
          >
            {cat.emoji}
          </div>
        ))}
        {/* 中央に「にゃーん」の文字表示 */}
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "100%",
            textAlign: "center",
            transform: "translate(-50%, -50%)",
            fontSize: "20vw",
          }}
        >
          <span
            style={{
              ...goldTextStyle,
              animation: "enlarge 2s 2s forwards",
              WebkitAnimation: "enlarge 2s 2s forwards",
            }}
          >
            にゃーん
          </span>
        </div>
        
        {/* 画面下部のガイダンステキスト */}
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            padding: "10px 20px",
            borderRadius: "20px",
            fontSize: "16px",
            fontWeight: "bold",
            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            zIndex: 10
          }}
        >
          タップするといくつかの猫がはねます
        </div>
      </div>
    </>
  );
}


export default App;
